const LOGO_STEWART = `img/logo.svg`;

// URLs to Apps
const URL_STEWART_CONNECT = process.env.REACT_APP_URL_STEWART_CONNECT;
const URL_VIRTUAL_UNDERWRITER = process.env.REACT_APP_URL_VIRTUAL_UNDERWRITER;
const URL_STEWART_ACADEMY = process.env.REACT_APP_URL_STEWART_ACADEMY;
const URL_STEWART_SPECIAL_ALERTS =
  process.env.REACT_APP_URL_STEWART_SPECIAL_ALERTS;
const URL_CONNECT_CLOSE = process.env.REACT_APP_URL_CONNECT_CLOSE || "#";
const URL_STEWART_PRIOR_FILES = process.env.REACT_APP_URL_STEWART_PRIOR_FILES || "https://connectpriorfiles.stewart.com";

// Identification code of each App
const APP_CODE_STEWART_CONNECT = "STW_CONNECT_APP_CODE"; // only text because will use another type of validation
const APP_CODE_STEWART_PRIOR_FILES =
  process.env.REACT_APP_CODE_STEWART_PRIOR_FILES;
const APP_CODE_STEWART_MARKETING = process.env.REACT_APP_CODE_STEWART_MARKETING;
const APP_CODE_VIRTUAL_UNDERWRITER =
  process.env.REACT_APP_CODE_VIRTUAL_UNDERWRITER;
const APP_CODE_STEWART_SPECIAL_ALERTS =
  process.env.REACT_APP_CODE_STEWART_SPECIAL_ALERTS;
const APP_CODE_STEWART_ACADEMY = process.env.REACT_APP_CODE_STEWART_ACADEMY;
const APP_CODE_CONNECT_CLOSE =
  process.env.REACT_APP_CODE_CONNECT_CLOSE || "app_connect_close";

const APPINSIGHTS_CONNECTION_STRING =
  process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING;

const ALWAYS_VISIBLE_APP_CODES = process.env.REACT_APP_ALWAYS_VISIBLE_APP_CODES;

export {
  LOGO_STEWART,
  URL_STEWART_CONNECT,
  URL_VIRTUAL_UNDERWRITER,
  URL_STEWART_ACADEMY,
  URL_STEWART_SPECIAL_ALERTS,
  URL_CONNECT_CLOSE,
  URL_STEWART_PRIOR_FILES,
  APP_CODE_STEWART_CONNECT,
  APP_CODE_STEWART_PRIOR_FILES,
  APP_CODE_STEWART_MARKETING,
  APP_CODE_VIRTUAL_UNDERWRITER,
  APP_CODE_STEWART_SPECIAL_ALERTS,
  APP_CODE_STEWART_ACADEMY,
  APP_CODE_CONNECT_CLOSE,
  APPINSIGHTS_CONNECTION_STRING,
  ALWAYS_VISIBLE_APP_CODES,
};
