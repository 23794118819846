export default function SCard(props) {
  const imgLink = `img/${props.cardImg}`;
  return (
    <>
      <div className="card h-100" style={{ width: "216px", border: "0px" }}>
        <a
          href={props.cardLink}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <img
            src={imgLink}
            className="card-img-top"
            style={{ width: "64px", margin: "16px 16px 0px 16px" }}
            alt="Application icon"
          ></img>
        </a>
        <a
          href={props.cardLink}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <div className="card-body">
            <p
              className="card-title"
              style={{ fontSize: "12px", color: "#2D2F33" }}
            >
              <b>{props.cardTitle}</b>
            </p>
            <p
              className="card-text"
              style={{ fontSize: "12px", color: "#5b626b" }}
            >
              {props.cardContent}
            </p>
          </div>
        </a>
      </div>
    </>
  );
}
