import { useEffect } from "react";
import {
  MsalAuthenticationTemplate,
  MsalProvider,
  useMsal,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { EventType } from "@azure/msal-browser";
import SCard from "./components/SCard";
import { useState } from "react";
import {
  LOGO_STEWART,
  URL_STEWART_CONNECT,
  URL_VIRTUAL_UNDERWRITER,
  URL_STEWART_ACADEMY,
  URL_STEWART_SPECIAL_ALERTS,
  URL_CONNECT_CLOSE,
  URL_STEWART_PRIOR_FILES,
  APP_CODE_STEWART_CONNECT,
  APP_CODE_STEWART_PRIOR_FILES,
  APP_CODE_STEWART_MARKETING,
  APP_CODE_VIRTUAL_UNDERWRITER,
  APP_CODE_STEWART_SPECIAL_ALERTS,
  APP_CODE_STEWART_ACADEMY,
  APP_CODE_CONNECT_CLOSE,
  ALWAYS_VISIBLE_APP_CODES,
} from "./utils/tools";
import { b2cPolicies } from "./authConfig";
import { compareIssuingPolicy } from "./utils/claimUtils";

import { reactPlugin } from "./utils/applicationInsightsHelper";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { Popover, ArrowContainer } from "react-tiny-popover";

import "./styles/App.css";

function LoadingComponent() {
  return <p>Verification in progress...</p>;
}

const Pages = ({ _error }) => {
  console.log(`URL_STEWART_PRIOR_FILES ${URL_STEWART_PRIOR_FILES}`);
  // this "_error" is from  the B2C documentation and is here just in case
  // it should always be empty
  if (_error) console.log(_error);

  const [filter, setFilter] = useState("");

  const cardsData = [
    {
      cardLink: URL_STEWART_CONNECT,
      cardImg: "circle_sc.png",
      cardTitle: "Connect Portal",
      cardContent:
        "Stewart's gateway to CPL, Jacket, Policy Accounting  Activity Reports, and Searches.",
      appCode: APP_CODE_STEWART_CONNECT,
    },

    {
      cardLink: URL_STEWART_PRIOR_FILES,
      cardImg: "circle_spf.png",
      cardTitle: "Prior Files",
      cardContent: "Access enables searching for prior Stewart Title policies.",
      appCode: APP_CODE_STEWART_PRIOR_FILES,
    },

    {
      cardLink: "#",
      cardImg: "circle_sm.png",
      cardTitle: "Marketing",
      cardContent:
        "A library of fresh marketing resources to help boost sales and increase business.",
      appCode: APP_CODE_STEWART_MARKETING,
    },

    {
      cardLink: URL_VIRTUAL_UNDERWRITER,
      cardImg: "circle_vu.png",
      cardTitle: "Virtual Underwriter",
      cardContent:
        "Stewart's underwriting manual with information available 24/7.",
      appCode: APP_CODE_VIRTUAL_UNDERWRITER,
    },

    {
      cardLink: URL_STEWART_SPECIAL_ALERTS,
      cardImg: "circle_ssa.png",
      cardTitle: "Special Alerts",
      cardContent:
        "Reduce risk of fraud by searching names of all parties to your transaction.",
      appCode: APP_CODE_STEWART_SPECIAL_ALERTS,
    },

    {
      cardLink: URL_STEWART_ACADEMY,
      cardImg: "circle_saca.png",
      cardTitle: "Academy",
      cardContent:
        "Educational and Training courses to help your business succeed.",
      appCode: APP_CODE_STEWART_ACADEMY,
    },
    {
      cardLink: URL_CONNECT_CLOSE,
      cardImg: "circle_connect_close.png",
      cardTitle: "Connect Close",
      cardContent:
        "Our title, escrow and closing software allowing our users to generate real estate closing documents with ease and reduce your closing times.",
      appCode: APP_CODE_CONNECT_CLOSE,
    },
  ];

  let accountPresent = false;

  let jobTitle = " ";
  let displayName = " ";

  let displayAppStewartConnect = false;

  let userInitials = " ";
  let userEmail = " ";

  let stringValidAppsForUser = "";

  // get info from logged in user
  const { instance, accounts } = useMsal();

  if (accounts && accounts.length > 0) {
    if (accounts[0]) {
      accountPresent = true;
      // react account info
      //console.log(`********accounts[0]`);
      //console.log(JSON.stringify(accounts[0]));

      jobTitle = accounts[0].idTokenClaims.jobTitle;
      displayName = accounts[0].name;
      userEmail = accounts[0].username;

      // calculate userInitials
      if (displayName) {
        let onlyData = displayName.trim();
        if (onlyData.length === 1) {
          // just one letter
          userInitials = onlyData;
        }
        if (onlyData.length >= 1) {
          if (onlyData.indexOf(" ") === -1) {
            // just one name
            userInitials = onlyData.charAt(0);
          } else {
            // use first letter of each word
            const words = onlyData.split(" ");
            userInitials = words[0].charAt(0) + words[1].charAt(0);
          }
        }
      }

      // validate Apps
      stringValidAppsForUser =
        accounts[0].idTokenClaims.extension_UserPermissions;
      const stewartConnectId =
        accounts[0].idTokenClaims.extension_StewartConnectUserID;
      if (stewartConnectId) {
        if (stewartConnectId.length >= 1) {
          displayAppStewartConnect = true;
        }
      }
    }
  }

  function displayCardValidation(card, filter) {
    // console.log(`displayCardValidation_filter        :${filter}`);
    // console.log(`displayCardValidation_card.appCode  :${card.appCode}`);
    // Verify card Stewart Connect
    if (card.appCode === APP_CODE_STEWART_CONNECT) {
      if (displayAppStewartConnect === false) {
        // no need to continue, app disabled by B2C attribute StewartConnectID
        return false;
      }
      // validate UI filter
      if (
        card.cardTitle.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
        filter === ""
      )
        return true;
    } else {
      // Verify if card should always be visible
      if (ALWAYS_VISIBLE_APP_CODES) {
        if (ALWAYS_VISIBLE_APP_CODES.indexOf(card.appCode) !== -1) {
          // validate UI filter
          if (
            card.cardTitle.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
            filter === ""
          )
            return true;
        }
      }
      if (stringValidAppsForUser) {
        // first validate if user has access according to B2C
        if (stringValidAppsForUser.indexOf(card.appCode) === -1) {
          // no need to continue, app disabled by B2C attribute UserPermissions
          return false;
        }
        // validate UI filter
        if (
          card.cardTitle.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
          filter === "" ||
          ALWAYS_VISIBLE_APP_CODES.indexOf(card.appCode) !== -1
        )
          return true;
      }
    }

    // if we reach this point no app was enabled by B2C (or by ui filter)
    return false;
  }

  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * an array of all accounts currently signed in and an inProgress value
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */

  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload.account
      ) {
        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth
         * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
         * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
         * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        if (
          event.payload.idTokenClaims["tfp"] === b2cPolicies.names.editProfile
        ) {
          // retrieve the account from initial sing-in to the app
          const originalSignInAccount = instance
            .getAllAccounts()
            .find(
              (account) =>
                account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
                account.idTokenClaims["tfp"] === b2cPolicies.names.signUpSignIn,
            );

          let signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            account: originalSignInAccount,
          };

          // silently login again with the signUpSignIn policy
          instance.ssoSilent(signUpSignInFlowRequest);
        }
      }
      //eventrp
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line
  }, [instance]);

  const handleLogoutRedirect = () => {
    instance.logoutRedirect();
  };

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        errorComponent={Pages}
        loadingComponent={LoadingComponent}
      ></MsalAuthenticationTemplate>

      {/* Header */}
      {accountPresent && (
        <div className="container mt-3 mb-3">
          <div className="row">
            <div className="col my-auto">
              <img
                src={LOGO_STEWART}
                style={{ width: "168px" }}
                alt="Stewart logo"
              ></img>
            </div>
            <div className="col my-auto border">
              <div className="row mx-auto">
                <div className="col-auto d-none d-lg-block my-auto">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#5B626B"
                    className="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>{" "}
                </div>
                <div className="col">
                  <input
                    id="filter"
                    name="filter"
                    type="text"
                    placeholder="Search Apps"
                    value={filter}
                    style={{
                      fontSize: "14px",
                      border: "0px",
                      background: "unset",
                      borderWidth: "0px",
                      outline: "none",
                      boxShadow: "none",
                      paddingTop: "12px",
                      paddingBottom: "12px",
                    }}
                    onChange={(event) => setFilter(event.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="col" style={{ textAlign: "end" }}>
              <div className="row">
                <b>
                  <span style={{ fontSize: "16px", color: "#2D2F33" }}>
                    {displayName}
                  </span>
                </b>
              </div>
              <div className="row">
                <span style={{ fontSize: "12px" }}>{jobTitle}</span>
              </div>
            </div>
              <Popover
                isOpen={isPopoverOpen}
                onClickOutside={() => setIsPopoverOpen(false)}
                positions={["bottom", "left"]} // preferred positions by priority
                content={({ position, childRect, popoverRect }) => (
                  <ArrowContainer
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor={"#ffffff"}
                    arrowSize={16}
                  >
                    <div
                      className="container"
                      style={{ backgroundColor: "#FFFFFF", padding: "16px" }}
                    >
                      <div className="row justify-content-between">
                        <div className="col-auto">
                          <span style={{ fontSize: "14px" }}>
                            Stewart Title
                          </span>
                        </div>
                        <div className="col-auto" style={{ textAlign: "end" }}>
                          <a
                            href="#"
                            onClick={handleLogoutRedirect}
                            rel="noreferrer"
                            style={{
                              fontSize: "12px",
                              color: "#6c757d!important",
                            }}
                          >
                            Sign Out
                          </a>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-auto">
                          <div className="circleForInitialsBigger">
                            <span style={{ fontSize: "28px" }}>
                              {userInitials}
                            </span>
                          </div>
                        </div>
                        <div className="col-auto align-self-center">
                          <div className="row">
                            <b>
                              <span
                                style={{ fontSize: "16px", color: "#2D2F33" }}
                              >
                                {displayName}
                              </span>
                            </b>
                          </div>
                          <div className="row">
                            <span style={{ fontSize: "12px" }}>
                              {userEmail}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ArrowContainer>
                )}
              >
                <div className="col-auto">
                <div
                  className="circleForInitials"
                  onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                >
                  {userInitials}
                </div>
                </div>
              </Popover>
          </div>
        </div>
      )}

      {/* Begin page content */}
      {accountPresent && (
        <main className="flex-shrink-0">
          <div id="mainCenterContainer" className="container">
            <div id="mainRowContainer"className="row gy-5">
              {cardsData
                .filter((f) => displayCardValidation(f, filter))
                .map((f) => (
                  <div className="col-auto" key={f.cardTitle}>
                    <SCard
                      cardLink={f.cardLink}
                      cardImg={f.cardImg}
                      cardTitle={f.cardTitle}
                      cardContent={f.cardContent}
                    ></SCard>
                  </div>
                ))}
            </div>
          </div>
        </main>
      )}

      {/* Footer */}
      {accountPresent && (
        <footer className="footer-10 mt-auto mb-3">
          <div className="container">
            <div className="row mt-5 pt-4">
              <div className="col-md-6 col-lg-8 mb-md-0 mb-4">
                <span className="text-muted" style={{ fontSize: "12px" }}>
                  @ {new Date().getFullYear()} Stewart Information Services
                  Corporation. All rights reserved.
                </span>
              </div>
              <div className="col-md-6 col-lg-4 text-md-right">
                <a
                  href="https://www.stewart.com/en/privacy.html"
                  target="_blank"
                  rel="noreferrer"
                  style={{ fontSize: "12px", color: "#6c757d!important" }}
                >
                  Privacy
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a
                  href="https://www.stewart.com/en/terms-of-use.html"
                  target="_blank"
                  rel="noreferrer"
                  style={{ fontSize: "12px", color: "#6c757d!important" }}
                >
                  Terms of Use
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a
                  href="https://www.stewart.com/en/protecting-customer-information.html"
                  target="_blank"
                  rel="noreferrer"
                  style={{ fontSize: "12px", color: "#6c757d!important" }}
                >
                  Protecting Customer Information
                </a>
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

const App = ({ instance }) => {
  return (
    <AppInsightsErrorBoundary
      onError={() => <p>Error processing</p>}
      appInsights={reactPlugin}
    >
      <MsalProvider instance={instance}>
        <Pages />
      </MsalProvider>
    </AppInsightsErrorBoundary>
  );
};

export default App;
